.thin-menu {
  position: relative;
  width: 100%;
  background: #333;
  color: #fff;
  font-size: 12px;
  font-family: Arial, sans-serif;
}

.menu-bar {
  display: flex;
  justify-content: space-around;
  padding: 5px 0;
  border-bottom: 1px solid #555;
}

.menu-item {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 5px 10px;
  color: #fff;
  transition: background 0.3s ease, color 0.3s ease;
}

.menu-item:hover {
  background: #444;
  color: #f9f9f9;
}

.menu-icon {
  margin-right: 6px;
  font-size: 14px;
}

.mega-menu {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background: #fff;
  color: #333;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 15px 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.sub-category {
  margin: 10px;
  width: calc(25% - 20px);
  display: flex;
  align-items: center; /* Align items in the center vertically */
}

.sub-category h4 {
  font-size: 14px;
  margin-bottom: 0; /* Remove bottom margin to align with the icon */
  color: #000;
  margin-left: 8px; /* Add space between icon and label */
}

.sub-category h4 a {
  text-decoration: none;
  color: #333;
  transition: color 0.3s ease;
}

.sub-category h4 a:hover {
  color: #4A9C8A; /* Highlight color for hover */
}

.sub-category-icon {
  color: #4A9C8A; /* Adjust color as needed */
  font-size: 16px; /* Adjust the size of the icon */
}
