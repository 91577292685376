/* Reset & Basic Setup */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html, body {
  height: 100%;
  font-family: Arial, sans-serif;
  color: #333;
  background-color: #f8f9fa;
}
body {
  display: flex;
  flex-direction: column;
  font-size: 16px;
}

/* Header Styling */
header {
  background-color: #ffffff;
  color: #333;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 30px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 1000;
}
header .logo img {
  height: 45px;
}


/* Navigation Links */
nav ul {
  list-style: none;
  display: flex;
  gap: 20px;
  align-items: center;
}
nav ul li a {
  color: #333;
  text-decoration: none;
  font-size: 15px;
  padding: 8px 12px;
  border-radius: 4px;
  transition: background-color 0.3s;
}
nav ul li a:hover {
  background-color: #e9ecef;
}
.login-button {
  background-color: #007bff;
  color: white;
  padding: 8px 16px;
  border-radius: 4px;
  text-decoration: none;
  font-size: 15px;
  transition: background-color 0.3s;
}
.login-button:hover {
  background-color: #0056b3;
}

/* Main Content */
.content {
  flex: 1;
  padding: 30px;
  max-width: 1200px;
  margin: 0 auto;
}

/* Footer Styling */
footer {
  background-color: #343a40;
  color: #ffffff;
  padding: 20px;
  text-align: center;
  margin-top: auto;
  font-size: 14px;
}
footer .footer-links {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 10px;
}
footer .footer-links a {
  color: #ffffff;
  text-decoration: none;
  padding: 5px 8px;
  border-radius: 4px;
  transition: background-color 0.3s;
}
footer .footer-links a:hover {
  background-color: #495057;
}
footer p {
  margin-top: 5px;
  font-size: 13px;
}
/* src/css/Home.css */
.home {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content {
  display: flex; /* Use flex to align sidebar and main content */
  flex: 1; /* Allow this section to expand */
}
.sidebar {
  width: 250px; /* Width of the sidebar */
  background-color: #f8f8f8; /* Optional background color */
}

.main-content {
  flex: 1; /* This will fill the remaining space */
  padding: 20px; /* Add padding for the main content */
}
.search-bar-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.selected-category {
  display: inline-flex;
  align-items: center;
  background-color: #e0e0e0;
  color: #333;
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 14px;
  margin-left: 10px;
}

.clear-category {
  margin-left: 8px;
  cursor: pointer;
  font-weight: bold;
  font-size: 16px;
  color: #888;
}

.clear-category:hover {
  color: #333;
}