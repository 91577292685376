/* AboutUs.css */

.about-us {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
  }
  
  h1 {
    text-align: center;
    margin-top: 20px;
    color: #333;
  }
  
  h2 {
    margin-top: 30px;
    color: #2c3e50;
  }
  
  p {
    line-height: 1.6;
    font-size: 1rem;
    margin-bottom: 20px;
  }
  
  ul {
    margin-top: 10px;
    list-style-type: square;
    padding-left: 20px;
  }
  
  ul li {
    margin-bottom: 10px;
  }
  
  footer {
    background-color: #333;
    color: white;
    text-align: center;
    padding: 10px;
    position: fixed;
    bottom: 0;
    width: 100%;
  }
  