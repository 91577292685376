header {
  background-color: #ffffff;
  color: #333;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 30px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 1000;
  overflow: visible; /* Make sure dropdown is not clipped */
}

header .mega-menu {
  position: relative;
}

header .logo img {
  height: 45px;
}

.search-bar-head {
  flex-grow: 1;
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 0 20px;
}
  .search-bar-head input[type="text"] {
    width: 300px;
    padding: 10px 12px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    outline: none;
    transition: border-color 0.3s;
    font-size: 14px;
  }
  .search-bar-head input[type="text"]:focus {
    border-color: #4A9C8A;
  }
  .search-bar-head .search-button {
    background-color: #4A9C8A;
    border: none;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  .search-bar-head .search-button i {
    color: #ffffff;
  }
  .search-bar-head .search-button:hover {
    background-color: #0056b3;
  }
  nav ul {
    list-style: none;
    display: flex;
    gap: 20px;
    align-items: center;
  }
  nav ul li a {
    color: #333;
    text-decoration: none;
    font-size: 15px;
    padding: 8px 12px;
    border-radius: 4px;
    transition: background-color 0.3s;
  }
  nav ul li a:hover {
    background-color: #e9ecef;
  }
  .login-button-head {
    background-color: #4A9C8A;
    color: white;
    padding: 8px 16px;
    border-radius: 4px;
    text-decoration: none;
    font-size: 15px;
    transition: background-color 0.3s;
  }
  .login-button-head:hover {
    background-color: #4A9C8A;
  }
  .header-links {
    display: flex;
    gap: 20px;
    align-items: center;
    margin-left: 20px; /* Adjust as needed */
  }
  
  .header-links a {
    text-decoration: none;
    font-size: 16px;
    color: #333;
    display: flex;
    align-items: center;
  }
  
  .bold-text {
    font-weight: bold;
  }
  
  .header-links a i {
    margin-right: 8px; /* Space between icon and text */
  }
  
  .btn-login-signup {
    padding: 8px 16px;
    background-color: #4A9C8A; /* Blue color for the button */
    color: white;
    border: none;
    border-radius: 4px;
    text-align: center;
    font-weight: bold;
  }
  
  .btn-login-signup:hover {
    background-color: #3C8A74; /* Darker blue for hover effect */
    cursor: pointer;
  }
  
  .header-links a:hover {
    color: #4A9C8A; /* Change text color on hover for other links */
  }
  
  .download-app-img {
    width: 150px;
    height: 60px;
  }
  .user-location {
    margin-left: 20px;
    display: flex;
    align-items: center; /* Vertically aligns icon and text */
    cursor: pointer;
    gap: 8px; /* Adds spacing between the icon and the text */
  }
  
  .user-location i.clickable-icon {
    color: #007bff; /* Color of the location icon */
    transition: color 0.3s, transform 0.2s;
    font-size: 1.2rem; /* Adjust icon size */
  }
  
  .user-location i.clickable-icon:hover {
    color: #0056b3;
    transform: scale(1.2); /* Adds a hover effect */
  }
  
  .user-location p {
    margin: 0; /* Removes any default margins */
    font-size: 1rem; /* Adjust text size */
    color: #333; /* Text color */
    white-space: nowrap; /* Prevents text wrapping */
  }
  .auto-suggestions {
    position: absolute; /* Make it positioned relative to the closest positioned ancestor */
    top: calc(100% + 5px); /* Position it just below the search bar */
    left: 0;
    width: 100%; /* Match the width of the search bar */
    max-height: 300px; /* Limit height and enable scrolling */
    overflow-y: auto; /* Add vertical scrolling if needed */
    border: 1px solid #ccc; /* Optional border for visibility */
    background-color: #fff; /* Background to overlay properly */
    z-index: 9999; /* Ensure it appears above other elements */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional shadow for better visibility */
    border-radius: 4px; /* Optional rounded corners */
  }
  
  .suggestion-section {
    margin-bottom: 10px;
    padding: 10px;
  }
  
  .suggestion-section h5 {
    margin: 0;
    font-size: 14px;
    color: #555;
    text-transform: uppercase;
  }
  
  .suggestion-section ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .suggestion-section li {
    padding: 5px 10px;
    cursor: pointer;
  }
  
  .suggestion-section li:hover {
    background-color: #f0f0f0;
  }
  