.footer {
  padding: 20px;
  background-color: #3C8A74; /* Primary color background */
  color: #fff; /* White text for contrast */
  text-align: center;
  margin-top: auto;
  position: relative;
  font-family: 'Arial', sans-serif;
  border-top: 2px solid #fff; /* Subtle border for definition */
}

.footer-links {
  margin-bottom: 15px;
  display: flex;
  flex-wrap: wrap; /* Allow wrapping for responsiveness */
  justify-content: center;
  gap: 15px; /* Consistent spacing between links */
}

.footer-links a {
  color: #fff; /* White links for contrast */
  padding: 5px 10px;
  text-decoration: none;
  font-weight: bold;
  border-radius: 4px;
  background-color: transparent; /* Transparent background by default */
  transition: background-color 0.3s ease, color 0.3s ease;
}


.footer p {
  margin: 5px 0;
  font-size: 0.9em;
  opacity: 0.9; /* Slightly faded to distinguish from links */
}

.footer-note {
  margin-top: 10px;
  font-style: italic;
  font-size: 0.85em;
  color: #f0f0f0; /* Lighter text for note */
}

/* Add responsiveness */
@media (max-width: 768px) {
  .footer-links {
    flex-direction: column; /* Stack links for smaller screens */
    gap: 10px;
  }

  .footer-links a {
    padding: 10px; /* Larger tap-friendly padding for mobile */
  }
}
