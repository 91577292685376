.shop-card {
  display: flex;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  margin: 10px 0;
  background-color: #ffffff;
  width: 500px;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s, transform 0.2s;
}

.shop-card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  transform: translateY(-2px);
}

.shop-logo {
  height: 80px;
  width: 80px;
  border-radius: 5px;
  object-fit: cover;
  margin-right: 15px;
}

.shop-details {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.shop-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.shop-name {
  font-size: 1.1rem;
  font-weight: bold;
  color: #333;
  flex: 1;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-right: 10px;
}

.shop-icons {
  display: flex;
  gap: 5px;
}

.icon {
  font-size: 1.2rem;
  color: #555;
}

.shop-distance {
  font-size: 0.9rem;
  color: #888;
}

.shop-category {
  font-size: 0.9rem;
  color: #666;
  margin-top: 5px;
  max-width: 100%; /* Restrict the category width to the card */
  white-space: normal; /* Allow wrapping of text */
  overflow: hidden; /* Hide anything outside the boundaries */
  text-overflow: ellipsis; /* Adds ellipsis for extremely long single words */
  line-height: 1.2; /* Adjust line height for better readability */
}

.shop-timings {
  font-size: 0.85rem;
  color: #333;
  margin-top: 5px;
}
