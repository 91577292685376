.home {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  .content {
    flex: 1;
    display: flex;
    justify-content: center;
    padding: 20px;
  }
  
  .main-content {
    max-width: 800px;
    width: 100%;
    padding: 20px;
    background-color: #ffffff;
    border: 1px solid #dee2e6;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .main-content h1 {
    font-size: 2rem;
    margin-bottom: 1rem;
    color: #343a40;
  }
  
  .main-content p {
    line-height: 1.6;
    color: #495057;
  }
  
  .main-content a {
    color: #007bff;
    text-decoration: none;
  }
  
  .main-content a:hover {
    text-decoration: underline;
  }
  