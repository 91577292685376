/* ContactUs.css */

.contact-us {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
  }
  
  h1 {
    text-align: center;
    margin-top: 20px;
    color: #333;
  }
  
  p {
    text-align: center;
    font-size: 1rem;
    margin-bottom: 20px;
  }
  
  form {
    width: 80%;
    max-width: 600px;
    margin: 0 auto;
    background-color: #f4f4f4;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  label {
    font-weight: bold;
    display: block;
    margin-bottom: 8px;
  }
  
  input,
  textarea {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    border-radius: 4px;
    border: 1px solid #ccc;
    margin-bottom: 10px;
  }
  
  textarea {
    height: 150px;
  }
  
  .submit-btn {
    background-color: #007bff;
    color: white;
    font-size: 1rem;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .submit-btn:hover {
    background-color: #0056b3;
  }
  
  .form-status {
    text-align: center;
    font-size: 1.1rem;
    color: green;
    margin-top: 20px;
  }
  