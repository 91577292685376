/* General Container */
.shop-detail-container {
  display: flex;
  justify-content: center;
  padding: 30px;
  background-color: #f5f7fa;
  color: #2e2e2e;
}

/* Shop Content Layout */
.shop-content {
  display: flex;
  width: 100%;
  max-width: 1200px;
  gap: 25px;
}

/* Shop Detail Section */
.shop-detail {
  flex: 0.7;
  padding: 25px;
  border-right: 1px solid #d9d9d9;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.07);
  border-radius: 8px;
}

/* Header Details */
.shop-header-details {
  position: relative;
  height: 220px;
  border-radius: 8px;
  margin-bottom: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-weight: 700;
  font-size: 1.8em;
}

/* Full Background */
.full-background {
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  color: #f9f9f9;
}

/* Split Header Layout */
.split-header {
  display: flex;
  width: 100%;
  height: 100%;
}

.header-image img {
  width: 120px;
  height: 120px;
  border-radius: 8px;
  object-fit: cover;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
}

.header-info {
  flex: 0.6;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px;
  color: #2b2b2b;
  font-weight: 500;
}

/* Category Labels */
.category-labels {
  display: flex;
  gap: 10px;
  margin-top: 15px;
}

.category-label {
  background-color: #4A9C8A;
  color: #ffffff;
  padding: 6px 12px;
  border-radius: 15px;
  font-size: 0.9em;
  font-weight: 500;
}

/* Icon Section */
.icon-section {
  display: flex;
  gap: 15px;
  margin-top: 15px;
}

.icon-button {
  background: none;
  border: none;
  cursor: pointer;
  color: #4A9C8A;
  font-size: 1.6em;
  transition: color 0.2s ease-in-out;
}

.icon-button:hover {
  color: #347568;
}

/* Information Sections */
.shop-info-section {
  margin-bottom: 25px;
  padding-top: 15px;
  border-top: 1px solid #d9d9d9;
}

.shop-info-section h2 {
  font-size: 1.2em;
  color: #2e2e2e;
  font-weight: 600;
  margin-bottom: 10px;
}

/* Right Section - Chat and Gallery */
.right-section {
  flex: 0.3;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/* Chat Box */
.chat-box {
  padding: 15px;
  background-color: #f5f5f5;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 10px;
  color: #333;
  font-size: 0.9em;
}

.chat-box input[type="text"],
.chat-box button {
  height: 40px;
  border-radius: 6px;
}

.android-app-button {
  margin-top: 15px;
  padding: 12px 24px;
  background-color: #4A9C8A;
  color: #ffffff;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 600;
  transition: background-color 0.2s ease-in-out;
}

.android-app-button:hover {
  background-color: #347568;
}

/* Modal */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background: #ffffff;
  padding: 25px;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  text-align: center;
  width: 320px;
  max-width: 90%;
}

.modal-content h4 {
  font-size: 1.2em;
  margin-bottom: 15px;
}

.modal-content button {
  padding: 10px 24px;
  background-color: #4A9C8A;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 600;
  transition: background-color 0.2s ease-in-out;
}

.modal-content button:hover {
  background-color: #347568;
}

/* Gallery Images */
.shop-images-gallery {
  background-color: #ffffff;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.gallery-images {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 10px;
}

.gallery-image {
  width: 80px;
  height: 80px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;
}

.gallery-image:hover {
  transform: scale(1.05);
}
