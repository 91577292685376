.category-badge-wrapper {
  width: 100%; /* Full width */
  background-color: #f7f7f7; /* Light background */
  padding: 5px; /* Add spacing */
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  display: flex; /* Use flexbox for layout */
  justify-content: space-between; /* Space out text and close button */
  align-items: center; /* Center items vertically */
  position: relative;
  z-index: 1;
}

.category-breadcrumb {
  font-size: 17px; /* Adjust font size */
  color: #333; /* Standard text color */
  text-align: left; /* Align text to the left */
}

.category-breadcrumb strong {
  font-weight: bold;
}

.close-button {
  background: none;
  border: none;
  font-size: 20px;
  font-weight: bold;
  color: #333; /* Match button color with the text */
  cursor: pointer;
  margin-left: 10px; /* Add spacing from the text */
  position: relative;
}

.close-button:hover {
  color: #000; /* Slightly darker color on hover */
}
