.shop-list {
  display: flex;
  flex-direction: column;
  gap: 10px; /* Space between shop items */
  align-items: center; /* Center the items horizontally */
}

.shop-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
  height: auto; /* Adjust height to fit content */
  width: 500px; /* Fixed width */
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s, transform 0.2s;
  overflow: hidden; /* Prevent content overflow */
}

.shop-item:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  transform: translateY(-2px);
}

.shop-item img {
  height: 80px; /* Adjusted size for compact image */
  width: 80px;
  border-radius: 5px;
  object-fit: cover;
  margin-right: 15px;
}

.shop-item-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
  overflow: hidden; /* Prevent overflow in case of long text */
}

.shop-item-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.shop-item-header h3 {
  margin: 0;
  font-size: 1.1rem;
  color: #333;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.shop-icons {
  display: flex;
  gap: 5px;
  align-items: center;
}

.shop-icons img {
  height: 16px;
  width: 16px;
  object-fit: contain;
}

.shop-distance {
  font-size: 0.9rem;
  color: #888;
  margin-left: 10px;
}

.shop-item-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.85rem;
  color: #666;
}

.shop-category {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.shop-timings {
  color: #333;
}
.category-badge {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0 20px;
}

.category-badge span {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border-radius: 20px;
  font-size: 1rem;
  font-weight: bold;
  text-transform: capitalize; /* Makes the category name start with an uppercase letter */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.category-badge-wrapper {
  display: flex;
  justify-content: center; /* Centers the badge horizontally */
  margin-bottom: 20px; /* Adds space between the badge and listings */
}

.category-badge {
  background-color: #007bff; /* Blue background for the badge */
  color: white; /* White text for contrast */
  padding: 10px 20px; /* Space inside the badge */
  border-radius: 20px; /* Makes the badge pill-shaped */
  font-size: 1rem;
  font-weight: bold;
  text-transform: capitalize; /* Capitalizes the first letter of the category */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Adds a slight shadow for depth */
  display: inline-block;
}
